import {isRtlLanguage} from '@wix/wix-events-commons-statics'
import {IWidgetControllerConfig, IWixAPI} from 'native-components-infra/dist/src/types/types'
import {Store} from 'redux'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {getLanguage} from '../../commons/selectors/environment'
import {withErrorBoundary} from '../../commons/services/error'
import {getExperimentsByScope} from '../../commons/utils/experiments'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {isSSR} from '../../commons/utils/wix-code-api'
import {DetailsPageProps} from '../components/app/interfaces'
import reducers from '../reducers'
import {createDetailsPageFedopsLogger} from '../services/fedops'
import {Actions, State, StoreExtraArgs} from '../types/state'
import {Api} from '../utils/api'

export const createDetailsPageController = async (controller: IWidgetControllerConfig) => {
  return Promise.resolve({
    pageReady: async () => await pageReady(controller),
  })
}

const pageReady = withErrorBoundary(async (controller: IWidgetControllerConfig) => {
  const fedopsLogger = createDetailsPageFedopsLogger(controller)
  const store = await createStore(controller)
  const state = store.getState()
  const actions = exportedActions(store, fedopsLogger.onAppLoaded)

  const props: DetailsPageProps = {
    state,
    actions,
    isRTL: isRtlLanguage(getLanguage(state)),
    staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
  }

  controller.setProps(props)

  if (isSSR(controller.wixCodeApi)) {
    fedopsLogger.onSSRPageReady()
  }
})

// const createBiMiddleware = (biParams: BiParams) => [
//   createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
//   createUouBiMiddlewareWithBiParams(biParams, eventsUou),
// ]

const createStore = withErrorBoundary(async (controller: IWidgetControllerConfig) => {
  const {wixCodeApi, compId} = controller
  const serverApi = new Api(controller)

  // const biMiddleware = createBiMiddleware({
  //   wixCodeApi,
  //   platformAPIs: controller.platformAPIs,
  //   appParams: controller.appParams,
  //   compId: controller.compId,
  //   user,
  // })
  //
  // const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData: await getInitialData(serverApi, wixCodeApi),
    extraArguments: {serverApi, wixCodeApi, compId},
    middleware: [],
  })

  // await store.dispatch(updateStyleParams(controller.config.style.styleParams))
  await store.dispatch(<any>setBaseEnvironment())

  subscribeToStateChanges(controller, store)

  return store
})

const getInitialData = withErrorBoundary(
  async (serverApi: Api, wixCodeApi: IWixAPI): Promise<Partial<State>> => {
    const slug = wixCodeApi.location.path[wixCodeApi.location.path.length - 1]

    const [
      experiments,
      // {event, component, siteSettings, demoEvents},
      {event, siteSettings, demoEvents},
    ] = await Promise.all([getExperimentsByScope(), serverApi.getData(slug)])

    return {
      event,
      experiments,
      siteSettings,
      multilingual: {
        currentLanguage: wixCodeApi.window.multilingual.currentLanguage,
        isEnabled: wixCodeApi.window.multilingual.isEnabled,
      },
      demoEvents,
      // component: {
      //   ...config,
      //   id,
      // },
    }
  },
)

const exportedActions = (store: Store, appLoaded: Function) => {
  //const dispatchActions = {}

  const actions: Actions = {
    //...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return actions
}
