import {IWidgetController, IWidgetControllerConfig, IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {createUouBiMiddlewareWithBiParams, isRtlLanguage, BiParams} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, Store} from 'redux'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {getSiteSettings} from '../../commons/actions/site-settings'
import {getLanguage} from '../../commons/selectors/environment'
import {logError, withErrorBoundary} from '../../commons/services/error'
import {getExperimentsByScope} from '../../commons/utils/experiments'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {isSSR} from '../../commons/utils/wix-code-api'
import {getComponentData, updateComponent} from '../actions/component'
import {closeAllEvents, getEvents, seeOtherEvents, setTab, shareEvent, toggleEventDetails} from '../actions/events'
import {internalNavigate, navigateToDetailsPage} from '../actions/navigation'
import {cancelRsvp} from '../actions/rsvp'
import {updateSettings} from '../actions/sdk'
import {downloadTicketsAction} from '../actions/tickets'
import {resetToLiveView} from '../actions/view'
import * as eventsUouEvents from '../bi/uou-bi-events-map'
import reducers from '../reducers'
import {createMembersPageFedopsLogger} from '../services/fedops'
import {Actions, MemberPageState, StoreExtraArgs, Tabs} from '../types/state'
import {Api} from '../utils/api'
import {getMembersAPI} from '../utils/members-api'

export async function createMembersPageController(controller: IWidgetControllerConfig): Promise<IWidgetController> {
  const {appParams, setProps} = controller
  const experiments = await getExperimentsByScope()

  return {
    async pageReady() {
      try {
        const fedopsLogger = createMembersPageFedopsLogger(controller)
        const store = await createStore(controller, experiments)
        const actions = exportedActions(store, fedopsLogger.onAppLoaded)
        const state = store.getState()

        setProps({
          state,
          actions,
          cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
          isRTL: isRtlLanguage(getLanguage(state)),
        })

        await Promise.all([
          actions.getEvents(Tabs.UPCOMING),
          actions.getEvents(Tabs.PAST),
          actions.getComponentData(),
          actions.getSiteSettings(),
        ])
        store.dispatch(setBaseEnvironment() as any)

        if (isSSR(controller.wixCodeApi)) {
          fedopsLogger.onSSRPageReady()
        }
      } catch (e) {
        logError(e)
        throw e
      }
    },
  }
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUouEvents)]

const createStore = withErrorBoundary(async (controller: IWidgetControllerConfig, experiments: ExperimentsBag) => {
  const serverApi = new Api(controller)
  const viewedSiteMemberId = await getSiteMemberId(controller)
  const pageUrl = await getPageUrl(controller)
  const {multilingual} = controller.wixCodeApi.window

  const initialData: Partial<MemberPageState> = {
    experiments,
    multilingual: {
      currentLanguage: multilingual.currentLanguage,
      isEnabled: multilingual.isEnabled,
    },
    user: {
      currentUserId: controller.wixCodeApi.user.currentUser.id,
      viewedSiteMemberId,
    },
  }

  const middleware = createBiMiddleware({
    wixCodeApi: controller.wixCodeApi,
    platformAPIs: controller.platformAPIs,
    appParams: controller.appParams,
    compId: controller.compId,
    user: initialData.user,
  })

  const store = createReduxStore<MemberPageState, StoreExtraArgs>({
    initialData,
    extraArguments: {
      wixCodeApi: controller.wixCodeApi,
      serverApi,
      compId: controller.compId,
      baseUrl: controller.appParams.baseUrls.baseUrl,
      pageUrl,
    },
    reducers,
    middleware,
  })

  subscribeToStateChanges(controller, store)

  return store
})

const getSiteMemberId = async (controller: IWidgetControllerConfig) =>
  controller.appParams.baseUrls.siteMemberId || (await getViewedUserId(controller.wixCodeApi))

const getViewedUserId = withErrorBoundary(async (wixCodeApi: IWixAPI) => {
  if (wixCodeApi.window.viewMode === 'Editor') {
    return ''
  }
  try {
    const membersApi = await getMembersAPI(wixCodeApi)
    return await membersApi.getViewedUser()
  } catch (e) {
    const currentUser = wixCodeApi.user.currentUser
    if (currentUser.loggedIn) {
      return currentUser.id
    }
    throw e
  }
})

const getPageUrl = async ({wixCodeApi}: IWidgetControllerConfig): Promise<string> => {
  const pageUrl = await wixCodeApi.site.getSectionUrl({sectionId: 'events'})
  return pageUrl?.url ?? null
}

const exportedActions = (store: Store, appLoaded): Actions => ({
  ...bindActionCreators(
    {
      getComponentData,
      getEvents,
      toggleEventDetails,
      cancelRsvp,
      shareEvent,
      getSiteSettings,
      navigateToDetailsPage,
      internalNavigate,
      updateSettings,
      updateComponent,
      setTab,
      resetToLiveView,
      downloadTicketsAction,
      closeAllEvents,
      seeOtherEvents,
    },
    store.dispatch,
  ),
  appLoaded,
})
