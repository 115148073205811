import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {RavenStatic} from 'raven-js'

let monitor: RavenStatic

export const initSentry = (controller: IWidgetControllerConfig) => {
  if (!monitor && typeof window === 'undefined') {
    const DSN = 'https://46ad76cef1c840139844ab4624c33fe2@sentry.wixpress.com/291'
    monitor = controller.platformAPIs.monitoring.createMonitor(DSN)
  }
}

export const logError = e => {
  monitor.captureException(e)
}

export const withErrorBoundary = cb => async (...params) => {
  try {
    return await cb(...params)
  } catch (e) {
    monitor.captureException(e)
    console.log(e)
  }
}
