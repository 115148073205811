import {EVENTS_DETAILS_PAGE_ID, EVENTS_MEMBERS_WIDGET_ID, EVENTS_WIDGET_ID} from '@wix/wix-events-commons-statics'
import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {initSentry} from './commons/services/error'
import {createDetailsPageController} from './details-page/controllers'
import {createMembersPageController as createMembersAppController} from './members-page/controllers/members-page'
import {createWidgetController} from './widget/controllers'

export const createControllers = (controllerConfigs: IWidgetControllerConfig[]) =>
  controllerConfigs.map(c => {
    if (c.type === EVENTS_WIDGET_ID) {
      initSentry(c)
      return createWidgetController(c)
    } else if (c.type === EVENTS_MEMBERS_WIDGET_ID) {
      initSentry(c)
      return createMembersAppController(c)
    } else if (c.type === EVENTS_DETAILS_PAGE_ID) {
      initSentry(c)
      return createDetailsPageController(c)
    }
  })
