import {getCalendarMonth, getFormattedStartTime, CalendarDay, WEEKDAY} from '@wix/wix-events-commons-statics'
import {Moment} from 'moment-timezone'
import * as moment from 'moment-timezone'
import {IStyle} from 'native-components-infra/dist/es/src/types/types'
import {getFullLocale, isEditor, isMobile} from '../../commons/selectors/environment'
import {AppProps} from '../components/app/interfaces'
import {eventComparator} from '../reducers/events'
import {State} from '../types/state'
import {getEvents} from './events'

const getEventsForCalendar = (state: State) => [...getEvents(state)].sort(eventComparator)

export const getGroupedEventsByDate = (state: State, monthReferenceDate?: Moment) => {
  const eventsMap: {[timestamp: number]: ExtendedEvent[]} = {}

  getEventsForCalendar(state).forEach(event => {
    if (event.scheduling.config.startDate) {
      const sortedStartDate = getAdjustedSchedulingDate(event.scheduling)

      if (monthReferenceDate && !sortedStartDate.isSame(monthReferenceDate, 'month')) {
        return
      }

      const startDateTimestamp = sortedStartDate.startOf('day').valueOf()

      eventsMap[startDateTimestamp] = eventsMap[startDateTimestamp] || []
      eventsMap[startDateTimestamp].push(event)
    }
  })

  return eventsMap
}
export const getCalendarWeeksWithEvents = (
  {state}: AppProps,
  referenceDate: Moment,
  weekStartDay?: WEEKDAY,
): CalendarWeeksWithEvents => {
  const eventsMap = getGroupedEventsByDate(state)

  return getCalendarMonth(referenceDate, weekStartDay).map(week =>
    week.map(day => ({
      ...day,
      events: eventsMap[day.date.valueOf()] || [],
    })),
  )
}

export const getReferenceDate = (state: State): Moment => {
  if (state.calendarLayout.referenceDate) {
    return moment(state.calendarLayout.referenceDate)
  } else if (isMobile(state)) {
    return getMobileReferenceDate(state)
  } else if (isEditor(state)) {
    return getEditorReferenceDate(state)
  } else {
    return moment().startOf('day')
  }
}

export const getEditorReferenceDate = (state: State): Moment => {
  const now = moment().startOf('day')

  const groupedEvents = Object.entries(getGroupedEventsByDate(state))
  const groupedEventsFromToday = groupedEvents.filter(([timestamp]) =>
    moment(Number(timestamp)).isSameOrAfter(moment().startOf('day')),
  )
  const groupedEventsTillToday = groupedEvents
    .filter(([timestamp]) => moment(Number(timestamp)).isBefore(moment().startOf('day')))
    .reverse()

  const multipleEventsInDay = ([, dayEvents]) => dayEvents.length > 1
  const anyEvent = ([, dayEvents]) => Boolean(dayEvents.length)

  const searches = [
    () => groupedEventsFromToday.find(multipleEventsInDay),
    () => groupedEventsTillToday.find(multipleEventsInDay),
    () => groupedEventsFromToday.find(anyEvent),
    () => groupedEventsTillToday.find(anyEvent),
  ]

  return (
    searches.reduce((referenceDate, search) => {
      if (!referenceDate) {
        const eventsEntry = search()
        return eventsEntry && moment(Number(eventsEntry[0])).startOf('day')
      } else {
        return referenceDate
      }
    }, null as Moment) || now
  )
}

export const getMobileReferenceDate = (state: State): Moment => {
  const now = moment().startOf('day')

  const upcomingDateWithEvents = Object.entries(getGroupedEventsByDate(state))
    .filter(([timestamp]) => moment(Number(timestamp)).isSameOrAfter(now))
    .find(([, dayEvents]) => Boolean(dayEvents.length))

  return upcomingDateWithEvents ? moment(Number(upcomingDateWithEvents[0])).startOf('day') : now
}

export const getCalendarMonthWithEvents = (
  {state}: AppProps,
  referenceDate: Moment = moment(),
): CalendarMonthWithEvents =>
  Object.entries(getGroupedEventsByDate(state, referenceDate)).reduce(
    (monthWithEvents, [stringTimestamp, dayEvents]) => {
      const timestamp = Number(stringTimestamp)

      monthWithEvents.push({
        events: dayEvents,
        date: moment(timestamp),
        day: moment(timestamp).date(),
        weekDay: moment(timestamp).day(),
        isCurrentMonth: true,
        today: moment().isSame(moment(timestamp), 'day'),
        past: moment(timestamp).isBefore(moment().startOf('day')),
      })

      return monthWithEvents
    },
    [] as CalendarMonthWithEvents,
  )

export const getSelectedDayEvents = ({state}: AppProps): EventWithTimeText[] =>
  getEventsForCalendar(state)
    .filter(({scheduling}) =>
      getAdjustedSchedulingDate(scheduling).isSame(moment(Number(state.calendarLayout.monthly.selectedDate)), 'day'),
    )
    .map(event => ({
      ...event,
      timeText: getFormattedStartTime(event, getFullLocale(state)),
    }))

// Converting date to match display date
// Business requires event date to be shown AS IS disregarding timezone
export const getAdjustedSchedulingDate = (scheduling: wix.events.Scheduling) => {
  const {
    config: {startDate, timeZoneId},
  } = scheduling
  return moment(moment.tz(startDate, timeZoneId).format('YYYY-MM-DD HH:mm'))
}

export const getSelectedDayEventsCount = ({state}: AppProps): number =>
  getEventsForCalendar(state).filter(({scheduling}) =>
    getAdjustedSchedulingDate(scheduling).isSame(moment(Number(state.calendarLayout.monthly.selectedDate)), 'day'),
  ).length

export const getEventDetailsStyleHash = (context: AppProps) => {
  const eventDetailsSettings = {
    booleans: ['listShowImage', 'listShowFullDate', 'listShowLocation', 'listShowDescription', 'listShowSocialShare'],
    numbers: [
      'calendarDetailsTitleTextSize',
      'calendarDetailsDateLocationTextSize',
      'calendarDetailsDescriptionTextSize',
    ],
  }

  const style: IStyle = context.host.style

  const settings = Object.entries(style.styleParams).reduce((result, [type, params]) => {
    if (eventDetailsSettings[type]) {
      result[type] = Object.entries(params).reduce((typeResult, [key, value]) => {
        if (eventDetailsSettings[type].includes(key)) {
          typeResult[key] = value
        }

        return typeResult
      }, {})
    }

    return result
  }, {})

  return JSON.stringify(settings)
}

export interface CalendarDayWithEvents extends CalendarDay {
  events: ExtendedEvent[]
}

export type CalendarMonthWithEvents = CalendarDayWithEvents[]
export type CalendarWeekWithEvents = CalendarDayWithEvents[]
export type CalendarWeeksWithEvents = CalendarWeekWithEvents[]

export interface EventWithTimeText extends wix.events.Event {
  timeText: string
}
