import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {Api as BaseApi} from '../../commons/utils/api'
import {getLanguage, getViewMode} from '../../commons/utils/wix-code-api'

export class Api extends BaseApi {
  constructor(controller: IWidgetControllerConfig) {
    super(controller)

    this.registrar = {}
  }

  getData = (slug: string) => {
    const {compId, wixCodeApi} = this.controller
    const language = getLanguage(wixCodeApi)
    const viewMode = getViewMode(wixCodeApi)
    const url = `/html/page-data/${slug}?instance=${this.getInstance()}&compId=${compId}&locale=${language}&viewMode=${viewMode}`

    return this.api.get(this.appendLinguisticParams(this.appendPetriOvr(url)))
  }
}
