import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {ITrackEventName, ITrackEventParams} from 'native-components-infra/dist/es/src/types/wix-sdk'
import {withErrorBoundary} from '../services/error'

export const SERVER_PREFIX = '/_api/wix-one-events-server'

export const getPageInfo = withErrorBoundary(async (wixCodeApi: IWixAPI) => {
  try {
    return await wixCodeApi.site.getCurrentPageInfo()
  } catch (e) {
    console.log(e)
    return {isHomePage: false}
  }
})

export const isSSR = (wixCodeApi: IWixAPI) => wixCodeApi.window.rendering.env === 'backend'

export const getServerBaseUrl = (wixCodeApi: IWixAPI) => {
  if (isSSR(wixCodeApi)) {
    return `${parseBaseUrl(wixCodeApi).root}${SERVER_PREFIX}`
  } else {
    return SERVER_PREFIX
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

export const getMultilingualFullLocale = ({window: {multilingual}}: IWixAPI): string | undefined => {
  if (multilingual.isEnabled) {
    const siteLanguage =
      multilingual.siteLanguages.find(lang => {
        return lang.languageCode === multilingual.currentLanguage
      }) || ({} as any)
    return siteLanguage.locale
  }
}

export const getViewMode = (wixCodeApi: IWixAPI) => {
  return (wixCodeApi.window.viewMode === 'Site' ? 'Site' : 'Editor').toLowerCase() // preview loads wrong data for Editor
}

export const getLanguage = (wixCodeApi: IWixAPI) => {
  return wixCodeApi.site.language
}

export const trackEvent = (wixCodeApi: IWixAPI, eventName: ITrackEventName, params: ITrackEventParams) =>
  wixCodeApi.window.trackEvent(eventName, params)
