import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {createFedopsLogger} from '../../commons/services/fedops'

export const createMembersPageFedopsLogger = (controller: IWidgetControllerConfig) => {
  const config = controller.appParams.baseUrls.iframeUrl
    ? 'wix-events-members-page-editor'
    : {
        appId: controller.appParams.appDefinitionId,
        widgetId: controller.type,
      }
  return createFedopsLogger(controller, config)
}
