import {SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import {encodeQueryParams, UrlQueryParams} from '@wix/wix-events-commons-statics'
import {IWixAPI} from 'native-components-infra/dist/es/src/types/types'
import {ModalActions} from '../types/state'

interface OpenModalProps {
  wixCodeApi: IWixAPI
  compId: string
  type: string
  params?: UrlQueryParams
}

export const openModal = ({wixCodeApi, compId, type, params}: OpenModalProps): Promise<{message: {reason: string}}> => {
  const queryParams = params ? encodeQueryParams(params) : ''
  return wixCodeApi.window.openPersistentPopup(
    `${SERVER_BASE_URL}/html/modal/${type}${queryParams}`,
    {
      theme: 'BARE',
      width: '100%',
      height: '100%',
      position: {
        origin: 'FIXED',
        placement: 'CENTER',
        x: 0,
        y: 0,
      },
    },
    compId,
  )
}

interface OpenGeneralModalProps extends OpenModalProps {
  onConfirm: Function
  onClose?: Function
}

export const openDialogModal = ({onConfirm, onClose, ...rest}: OpenGeneralModalProps) =>
  openModal(rest).then(response => {
    if (response.message.reason === ModalActions.OK) {
      onConfirm && onConfirm()
    } else {
      onClose && onClose()
    }
  })
