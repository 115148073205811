import {combineReducers} from 'redux'
import {demoEvents} from '../../commons/reducers/demo-events'
import {environment} from '../../commons/reducers/environment'
import {experiments} from '../../commons/reducers/experiments'
import {multilingual} from '../../commons/reducers/multilingual'
import {siteSettings} from '../../commons/reducers/site-settings'
import {State} from '../types/state'
import {event} from './event'

export default combineReducers<State>({
  experiments,
  environment,
  siteSettings,
  multilingual,
  demoEvents,
  event,
})
